import React from "react"
import { Helmet } from 'react-helmet'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/styles.css"

import Header from '../components/header'
import Footer from '../components/footer'

import company from '../data/company'

const IndexPage = ({ location }) => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoTraveling - Nous contacter</title>
        <meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
      </Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <div class="page_content single-page about-page contacts-page" style={{backgroundColor: '#171717', paddingBottom: 0}}>
          <div class="content-head">
            <div class="wrap">
              <div class="wrap_float">
                <div class="main">
                  <div class="title">
                    Pour nous contacter c'est ici ⬇️
                  </div>
                  <div class="contacts-columns">
                    <div class="column linkedin">
                      <div class="_title">LinkedIn</div>
                      <div class="text">
                        Tu peux voir direment avec notre fondateur sur son LinkedIn 🙂
                      </div>
                      <a href={company.linkedinFounder}>Profil du fondateur</a>
                    </div>
                    <div class="column email">
                      <div class="_title">Email</div>
                      <div class="text">
                        Les emails ça restera toujours la voie la plus efficace pour nous parler 😁
                      </div>
                      <a href={`mailto:${company.email}`}>{company.email}</a>
                    </div>
                    <div class="column location">
                      <div class="_title">Notre adresse</div>
                      <div class="text">
                        Mais... pas la peine de nous chercher, c'est le full-remote chez CoTraveling 😅
                      </div>
                      <a target="_blank" referrer='no-referrer' href="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Spaces+-+La+Defense,+Cours+Valmy">Voir sur Google Maps</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-body">
            <div class="mab-block" id="gmap">
            <iframe title="dubai-al-raffa" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Spaces+-+La+Defense,+Cours+Valmy&amp;ie=UTF8&amp;t=&amp;z=12&amp;iwloc=B&amp;output=embed"></iframe>
            </div>
          </div>
        </div>
        <Footer pathname={location.pathname} />
      </div>
    </main>
  )
}

export default IndexPage
